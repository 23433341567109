import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';

import imgSEO from '../../../assets/img/services/seo.jpg';

import SimilarCard from '../../../components/similar.card';

import similar2 from '../../../assets/img/heroes/thumbnails/contenu.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/optimisation.jpg';
import similar4 from '../../../assets/img/heroes/thumbnails/social2.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/search_engines.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <div id="top">
    <SEO
      title="Référencement naturel SEO | Agence digitale | MDS Digital Agency"
      description="MDS se tient continuellement informé quant aux nouvelles pratiques de référencement naturel et établit pour vous une stratégie SEO sur mesure."
      keywords="Référencement naturel"
    />
    <Menu />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Le référencement naturel ou SEO</h2>
      <p>
        Le référencement naturel consiste à adapter votre site internet pour répondre aux critères
        évolutifs établis par Google afin de le{' '}
        <strong>positionner en première page des résultats de recherche de Google</strong>.{' '}
      </p>
      <p>
        Contrairement au{' '}
        <AniLink cover bg="#fba515" to="/services/referencement/payant">
          référencement payant (SEA)
        </AniLink>
        , le référencement naturel ne s’achète pas. Il se construit en établissant une{' '}
        <strong>stratégie d’optimisation</strong> de différents paramètres de votre site web.{' '}
      </p>
      <p>
        MDS se tient continuellement informé quant aux nouvelles pratiques de référencement naturel.
      </p>
      <p>
        Voici comment, <strong>étape par étape</strong>, nous établissons une{' '}
        <strong>stratégie SEO sur mesure</strong>, adaptée à votre entreprise et à son secteur
        d’activité:
      </p>
      <ul>
        <li>
          Nous étudions le <strong>positionnement actuel</strong> de votre site web.
        </li>
        <li>
          La stratégie SEO de vos <strong>concurrents</strong> est également passée au crible.
        </li>
        <li>
          Nous déterminons une liste de mots-clés pour lesquels votre site internet doit être
          visible.
        </li>
        <li>
          Nous optimisons ces <strong>mots-clés</strong> sur votre site internet grâce à différentes
          techniques de référencement.
        </li>
        <li>
          Les résultats sont <strong>continuellement monitorés</strong> par notre équipe et nous
          intervenons pour garantir à long terme votre position sur les pages de Google.
        </li>
        <li>
          Nous vous communiquons les <strong>résultats obtenus</strong> de manière régulière.
        </li>
        <li>
          Vous bénéficiez d’<strong>une plus grande visibilité</strong> sur le web et d’
          <strong>un nombre de visiteurs accru</strong>.
        </li>
      </ul>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="La stratégie SEO de mon site internet doit-elle absolument être faite avant ou après sa publication ?"
        subtitle="Renseignez-vous dès aujourd’hui."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Gestion de contenu"
            url="/services/website/gestion-de-contenu/"
            img={similar2}
          />
          <SimilarCard
            text="Optimisation de site web"
            url="/services/website/optimisation/"
            img={similar3}
          />
          <SimilarCard text="Réseaux sociaux" url="/services/reseaux-sociaux/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
